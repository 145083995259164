import './RecomendationsForIndicators.scss';

import ContainerWYSIWYG from '../../ContainerWYSIWYG';
import Title from '../../Title';

interface Props {
  data: any;
}

export const RecomendationsForIndicators = ({ data }: Props) => {
  // * Полностью идентично с первым потом, кроме competentions_data[-1]
  // * можно кописпастить
  if (data.streams_count === 2) {
    return (
      <>
        {data.competentions_data[1].map((competention, index) => (
          <>
            {(competention.recomendation.text ||
              competention.recomendation.article ||
              competention.recomendation.books ||
              competention.recomendation.video ||
              competention.recomendation.podcasts ||
              competention.recomendation.courses) && (
              <div className='recomendationsForIndicators' data-cy='recomendationsForIndicatorsBlock' key={index}>
                <Title>Рекомендации по развитию компетенции «{competention.title}»</Title>

                {competention.recomendation.text && (
                  <div className='generalRecomendation'>
                    <ContainerWYSIWYG data={competention.recomendation.text} size='default' />
                  </div>
                )}

                <div className='indicatorContainer'>
                  {(competention.recomendation.books || competention.recomendation.article) && (
                    <div className='left'>
                      {competention.recomendation.books && (
                        <div className='indicatorRecomendation books'>
                          <div className='indicatorRecomendationTitle'>Книги</div>
                          <ContainerWYSIWYG data={competention.recomendation.books} size='default' />
                        </div>
                      )}
                      {competention.recomendation.article && (
                        <div className='indicatorRecomendation articles'>
                          <div className='indicatorRecomendationTitle'>Статьи</div>
                          <ContainerWYSIWYG data={competention.recomendation.article} size='default' />
                        </div>
                      )}
                    </div>
                  )}

                  {(competention.recomendation.video || competention.recomendation.podcasts || competention.recomendation.courses) && (
                    <div className='right'>
                      {competention.recomendation.video && (
                        <div className='indicatorRecomendation videos'>
                          <div className='indicatorRecomendationTitle'>Видео</div>
                          <ContainerWYSIWYG data={competention.recomendation.video} size='default' />
                        </div>
                      )}
                      {competention.recomendation.podcasts && (
                        <div className='indicatorRecomendation podcasts'>
                          <div className='indicatorRecomendationTitle'>Подкасты</div>
                          <ContainerWYSIWYG data={competention.recomendation.podcasts} size='default' />
                        </div>
                      )}
                      {competention.recomendation.courses && (
                        <div className='indicatorRecomendation courses'>
                          <div className='indicatorRecomendationTitle'>Курсы</div>
                          <ContainerWYSIWYG data={competention.recomendation.courses} size='default' />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        ))}
      </>
    );
  }

  return (
    <>
      {data.competentions_data.map((competention, index) => (
        <>
          {(competention.recomendation.text ||
            competention.recomendation.article ||
            competention.recomendation.books ||
            competention.recomendation.video ||
            competention.recomendation.podcasts ||
            competention.recomendation.courses) && (
            <div className='recomendationsForIndicators' data-cy='recomendationsForIndicatorsBlock' key={index}>
              <Title>Рекомендации по развитию компетенции «{competention.title}»</Title>

              {competention.recomendation.text && (
                <div className='generalRecomendation'>
                  <ContainerWYSIWYG data={competention.recomendation.text} size='default' />
                </div>
              )}

              <div className='indicatorContainer'>
                {(competention.recomendation.books || competention.recomendation.article) && (
                  <div className='left'>
                    {competention.recomendation.books && (
                      <div className='indicatorRecomendation books'>
                        <div className='indicatorRecomendationTitle'>Книги</div>
                        <ContainerWYSIWYG data={competention.recomendation.books} size='default' />
                      </div>
                    )}
                    {competention.recomendation.article && (
                      <div className='indicatorRecomendation articles'>
                        <div className='indicatorRecomendationTitle'>Статьи</div>
                        <ContainerWYSIWYG data={competention.recomendation.article} size='default' />
                      </div>
                    )}
                  </div>
                )}

                {(competention.recomendation.video || competention.recomendation.podcasts || competention.recomendation.courses) && (
                  <div className='right'>
                    {competention.recomendation.video && (
                      <div className='indicatorRecomendation videos'>
                        <div className='indicatorRecomendationTitle'>Видео</div>
                        <ContainerWYSIWYG data={competention.recomendation.video} size='default' />
                      </div>
                    )}
                    {competention.recomendation.podcasts && (
                      <div className='indicatorRecomendation podcasts'>
                        <div className='indicatorRecomendationTitle'>Подкасты</div>
                        <ContainerWYSIWYG data={competention.recomendation.podcasts} size='default' />
                      </div>
                    )}
                    {competention.recomendation.courses && (
                      <div className='indicatorRecomendation courses'>
                        <div className='indicatorRecomendationTitle'>Курсы</div>
                        <ContainerWYSIWYG data={competention.recomendation.courses} size='default' />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      ))}
    </>
  );
};
