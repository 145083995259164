import './RecomendationResult.scss';

import ContainerWYSIWYG from '../../ContainerWYSIWYG';
import Title from '../../Title';
import {reportColors} from '../../../helpers/colorPalete';
import {useLocation} from 'react-router-dom';

interface Props {
    data: {
        member_first_name: string;
        member_last_name: string;
        anketa_free_comment: boolean;
        comment_member: string;
        comment_hr: string;
        anketa_recomendation: string | string[];
        streams_count: number;
        client_color: string;
        answers: any;
        diagram_data: any;
    };
}

export const RecomendationResult: React.FC<Props> = ({data}) => {
    const location = useLocation();
    const {linksColor} = reportColors(data.client_color, data.streams_count);

    const indicatorPercent = (indicator) => {
        let result: object[] = [];
        data.diagram_data.forEach(diagram => {
            if (diagram[0] == indicator.title) {
                result.push(diagram[1]);
            }
        });

        return result;
    }

    const indicators = (answer) => {
        let indicatorsArray: object[] = [];

        answer.question.indicators.forEach(indicator => {
            indicatorsArray.push(
                <>
                    <p className='indicatorTitle'>Индикатор, оцениваемый в вопросе: "{indicator.title}"</p>
                    <p className='indicatorPercent'>Набранный процент: {indicatorPercent(indicator)}%</p>
                </>
            );
        });

        return indicatorsArray;
    }

    const answers = () => {
        let answersArray: object[] = [];
        data.answers.forEach((answer, index) => {
            if (answer.expert_comment !== null && answer.expert_comment !== "") {
                answersArray.push(
                    <div className='answer' key={index}>
                        <p className='questionText'>Вопрос: "{answer.question.text}"</p>
                        <>
                            {indicators(answer)}
                        </>
                        <p className='answerYourText'>Текст вопроса:</p>
                        <ContainerWYSIWYG size='default' data={answer.question.question_text} />
                        <p className='answerYourText'>Ваш ответ:</p>
                        <ContainerWYSIWYG size='default' data={answer.text} />
                        {answer.expert_comment !== "" ? (
                            <>
                                <p className='expertComment'>Комментарий эксперта:</p>
                                <ContainerWYSIWYG size='default' data={answer.expert_comment} />
                            </>
                        ) : null}
                    </div>
                );
            }
        });

        return answersArray;
    }

    return (
        <div className='recomendationResult'>
            <div className='comment'>
                <p>Добрый день, {data.member_first_name}!</p>
                <p></p>
                <p>Благодарим вас за участие в оценке, проведенной Центром Продуктовой Трансформации Акселератора ФРИИ. Мы ценим ваше время и вклад в этот процесс.</p>
                <p></p>
                <p>На основании полученных результатов ниже представлены подробные комментарии и рекомендации, которые помогут вам определить ваши сильные стороны и зоны развития.Мы уверены, что эта информация будет полезной для дальнейшего профессионального совершенствования и достижения новых высот в управлении продуктом.</p >
                <p></p>
                <p>Важно отметить, что результаты диагностики не всегда полностью отражают ваш реальный вклад на рабочем месте, поскольку успех в работе зависит не только от Hard Skills, но и от Soft Skills, а также вашего опыта и уникальности экспертизы.Таким образом, текущие результаты указывают, скорее, на потенциал, который предстоит реализовать.Дальнейшее точечное продуктовое обучение поможет вам нивелировать пробелы в областях профессионального и карьерного роста, а также повысить эффективность в решении рабочих задач.</p >
                <p></p>
                <p>Ниже вы можете изучить развернутые комментарии эксперта по вопросам, где вы набрали наименьшую оценку.Для вашего удобства мы продублировали ваши ответы.</p >
            </div>
            <div className='answers'>
                {answers()}
            </div>
        </div>
    )
}
