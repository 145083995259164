import './CompetentionsIndicators.scss';
import 'react-circular-progressbar/dist/styles.css';

import { isTesting3Streams, reportColors } from '../../../helpers/colorPalete';

import ContainerWYSIWYG from '../../ContainerWYSIWYG';

interface Competention {
  id: number;
  title: string;
  score: number;
  recomended_score: number;
  percent: number;
  real_percent: number;
  get_norm_percent: number;
  indicators: any[];
}
interface Props {
  data: {
    client_color: string;
    rate: number | number[];
    rate_percent: number | number[];
    max_rate: number | number[];
    streams_count: number;
    competentions_data: any;
    report_percents: boolean;
  };
}

export const CompetentionsIndicators: React.FC<Props> = ({ data }) => {
  const { iconsColor } = reportColors(data.client_color, data.streams_count);

  if (data.streams_count === 3 || isTesting3Streams) {
    return (
      <div className={'competentionsIndicators threeStreams ' + `${data.report_percents ? ' percents' : ' points'}`}>
        {data.competentions_data[1].map((competention, competentionIdx) => (
          <div className='competention' key={competentionIdx}>
            <div className='subTitle'>{competention.title}</div>
            <div className='subTitleEmoji'>
              <div className='emoji'>
                <div className='emojiIcon'></div>
                <div className='text'>необходимо развивать компетенцию для соответствия роли</div>
              </div>
              <div className='emoji'>
                <div className='emojiIcon' style={{ backgroundColor: iconsColor }}></div>
                <div className='text'>компетенция развита на достаточном уровне</div>
              </div>
            </div>
            <div className='scoring'>{data.report_percents ? 'Набрано %' : 'Набрано баллов из 3'}</div>
            {competention.indicators.map((indicator, indicatorIdx) => (
              <div className='indicator' key={indicatorIdx}>
                <ContainerWYSIWYG size='default' data={indicator.title} className='key' />
                <div className='value'>
                  {data.report_percents ? (
                    <>
                      <div className='valuePercentage firstStream'>{`${data.competentions_data[0][competentionIdx].indicators[indicatorIdx].real_percent} %`}</div>
                      <div className='valuePercentage secondStream'>{`${indicator.real_percent} %`}</div>
                      <div className='valuePercentage'>100 %</div>
                    </>
                  ) : (
                    <>
                      <div className='valuePoints firstStream'>{data.competentions_data[0][competentionIdx].indicators[indicatorIdx].score}</div>
                      <div className='valuePoints secondStream'>{data.competentions_data[1][competentionIdx].indicators[indicatorIdx].score}</div>
                      <div className='valuePoints'>{3}</div>
                    </>
                  )}
                  <div className={`valueEmoji` + `${indicator.get_norm ? ' good' : ' bad'}`} style={{ backgroundColor: indicator.get_norm ? iconsColor : '#252424' }} />
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }

  if (data.streams_count === 2) {
    return (
      <div className={'competentionsIndicators twoStreams' + `${data.report_percents ? ' percents' : ' points'}`}>
        {data.competentions_data[1].map((competention, competentionIdx) => (
          <div className='competention' key={competentionIdx}>
            <div className='subTitle'>{competention.title}</div>
            <div className='subTitleEmoji'>
              <div className='emoji'>
                <div className='emojiIcon'></div>
                <div className='text'>необходимо развивать компетенцию для соответствия роли</div>
              </div>
              <div className='emoji'>
                <div className='emojiIcon' style={{ backgroundColor: `rgb(${data.client_color})` }}></div>
                {/* // * Дефолный голубой цвет, вместо клиентского */}
                {/* <div className='emojiIcon' style={{ backgroundColor: iconsColor }}></div> */}
                <div className='text'>компетенция развита на достаточном уровне</div>
              </div>
            </div>
            <div className='scoring'>
              {data.report_percents ? (
                <>
                  <span style={{ paddingLeft: '5rem' }}>Входное</span>
                  <span style={{ paddingLeft: '21rem' }}>Выходное</span>
                </>
              ) : (
                'Набрано баллов из 3'
              )}
            </div>
            {competention.indicators.map((indicator, indicatorIdx) => (
              <div className='indicator' key={indicatorIdx}>
                <ContainerWYSIWYG size='default' data={indicator.title} className='key' />
                <div className='value'>
                  {data.report_percents ? (
                    <>
                      <div className='valuePercentage firstStream'>{`${data.competentions_data[0][competentionIdx].indicators[indicatorIdx].real_percent} %`}</div>
                      <div className='valuePercentage'>{`${indicator.real_percent} %`}</div>
                    </>
                  ) : (
                    <>
                      <div className='valuePoints firstStream'>{data.competentions_data[0][competentionIdx].indicators[indicatorIdx].score}</div>
                      <div className='valuePoints'>{indicator.score}</div>
                    </>
                  )}
                  <div
                    className={`valueEmoji` + `${indicator.get_norm ? ' good' : ' bad'}`}
                    style={{ backgroundColor: indicator.get_norm ? `rgb(${data.client_color})` : '#252424' }}
                  />
                  {/* // * Дефолный голубой цвет, вместо клиентского */}
                  {/* <div className={`valueEmoji` + `${indicator.get_norm ? ' good' : ' bad'}`} style={{ backgroundColor: indicator.get_norm ? iconsColor : '#252424' }} /> */}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className={'competentionsIndicators oneStream noTopPageBreak ' + `${data.report_percents ? ' percents' : ' points'}`}>
      {data.competentions_data.map((competention, competentionIdx) => (
        <div className='competention' key={competentionIdx}>
          <div className='subTitle'>{competention.title}</div>
          <div className='subTitleEmoji'>
            <div className='emoji'>
              <div className='emojiIcon'></div>
              <div className='text'>необходимо развивать компетенцию для соответствия роли</div>
            </div>
            <div className='emoji'>
              <div className='emojiIcon' style={{ backgroundColor: `rgb(${data.client_color})` }}></div>
              {/* // * Дефолный голубой цвет, вместо клиентского */}
              {/* <div className='emojiIcon' style={{ backgroundColor: iconsColor }}></div> */}
              <div className='text'>компетенция развита на достаточном уровне</div>
            </div>
          </div>
          <div className='scoring'>{data.report_percents ? 'Набрано %' : 'Набрано баллов из 3'}</div>
          {competention.indicators.map((indicator, indicatorIdx) => (
            <div className='indicator' key={indicatorIdx}>
              <ContainerWYSIWYG size='default' data={indicator.title} className='key' />
              <div className='value'>
                {data.report_percents ? (
                  <>
                    <div className='valuePercentage'>{`${indicator.real_percent} %`}</div>
                  </>
                ) : (
                  <>
                    <div className='valuePoints'>{indicator.score}</div>
                  </>
                )}
                <div
                  className={`valueEmoji` + `${indicator.get_norm ? ' good' : ' bad'}`}
                  style={{ backgroundColor: indicator.get_norm ? `rgb(${data.client_color})` : '#252424' }}
                />
                {/* // * Дефолный голубой цвет, вместо клиентского */}
                {/* <div className={`valueEmoji` + `${indicator.get_norm ? ' good' : ' bad'}`} style={{ backgroundColor: indicator.get_norm ? iconsColor : '#252424' }} /> */}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
